"use client";

import CountUp from "react-countup";

const Counter = ({ value }) => (
  <CountUp
    start={0}
    end={value}
    delay={0}
    separator="."
    duration={4}
    enableScrollSpy
    scrollSpyOnce
  >
    {({ countUpRef }) => <div ref={countUpRef}></div>}
  </CountUp>
);

export { Counter };
